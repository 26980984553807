import { useRef } from "react";


function AudioIcon(props) {

  // Define variables
  const audioPlayerRef = useRef(null);
  const audioUrl = props.audioUrl;
  const width = '24px';


  // Define functions
  function playAudio() {
    const audioPlayer = audioPlayerRef.current;
    if( audioPlayer ) {
      audioPlayer.volume = 1;
      audioPlayer.play().catch((e) => {
        // Catch error because this method won't work on mobile safari
      });
    }
  }


  return (

    <div class='flex-column' style={{width: width, height: width, justifyContent: 'center'}}>
      <audio ref={audioPlayerRef}>
        <source src={audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      <button
        class='icon icon-play'
        tabIndex='0'
        title='Hear the text spoken out loud'
        onClick={() => playAudio()}></button>
    </div>
  );
}

export default AudioIcon;