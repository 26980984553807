function AsessCard(props) {

  // Define variables
  const assessment = props.assessment;
  const status = assessment.status;
  const highlighted = assessment.highlighted;

  const testTakerDetails = assessment.testTakerDetails;
  const code = testTakerDetails.assessmentIdentifer;
  const completedDate = testTakerDetails.date;
  const updatedDate = testTakerDetails.updatedAt;

  const cefrSubLevel = assessment.cefrSubLevel;
  const scoresByType = assessment.scoresByType;
  const pronunciation = scoresByType?.pronunciation?.label;
  const grammar = scoresByType?.grammar?.label;
  const fluency = scoresByType?.fluency?.label;
  const vocabulary = scoresByType?.vocabulary?.label;
  const relevance = scoresByType?.relevance?.label;


  // Screen components
  let statusElement;
  if( completedDate ) {
    statusElement = <p class='assess-date-p'>Completed {completedDate}</p>;
  } else {
    let updatedElement;
    if( updatedDate ) {
      updatedElement = (
        <><br /><br /><br />Last activity<br />{updatedDate}</>
      );
    }

    statusElement = (
      <p class='assess-date-p'>
        {status}
        {updatedElement}
      </p>
    );
  }

  let bodyElement;
  if( completedDate ) {
    bodyElement = (
      <div class='flex-column assess-card-body'>

        <div class='flex-row assess-card-score-row'>
          <p>Fluency</p>
          <p>{fluency}</p>
        </div>

        <div class='flex-row assess-card-score-row'>
          <p>Relevance</p>
          <p>{relevance}</p>
        </div>

        <div class='flex-row assess-card-score-row'>
          <p>pronunciation</p>
          <p>{pronunciation}</p>
        </div>

        <div class='flex-row assess-card-score-row'>
          <p>Grammar</p>
          <p>{grammar}</p>
        </div>

        <div class='flex-row assess-card-score-row'>
          <p>Vocabulary</p>
          <p>{vocabulary}</p>
        </div>

      </div>
    );
  }


  return (
    <div class={`flex-column assess-card ${highlighted ? 'highlighted' : null}`}>

      <div class='flex-column assess-card-head'>
        <h2>{cefrSubLevel}&nbsp;</h2>
        <p style={{marginTop: '5px'}}><small>{cefrSubLevel ? 'CEFR' : ''}&nbsp;</small></p>
        <br />
        <p>{code}</p>
        {statusElement}
      </div>

      {bodyElement}

    </div>
  );
}

export default AsessCard;